// src/components/Position.js
import React, { useState } from 'react';
import { Card, CardContent, Typography, Button, TextField, Tooltip, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import styled from 'styled-components';
import axios from 'axios';
import TokenDetails from './TokenDetails';
import { ethers } from 'ethers';

const PositionCard = styled(Card)`
  max-width: 600px;
  border-radius: 15px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
`;

const Label = styled(Typography)`
  color: #666;
  font-weight: 500;
`;

const Value = styled(Typography)`
  font-weight: 700;
`;

const RangeIndicator = styled(Typography)`
  font-weight: 700;
  color: ${props => props.inRange ? '#4caf50' : '#f44336'};
`;

const StyledTextField = styled(TextField)`
  margin-top: 16px;
  margin-bottom: 16px;
  width: 100%;
`;

function tickToPrice(tick, decimals0, decimals1) {
  return (Math.pow(1.0001, tick)) / (Math.pow(10, decimals1 - decimals0));
}

function isStablecoin(symbol){
  const array=['USDC', 'USDT', 'DAI'];
  return array.includes(symbol);
}

function formatToSignificantFigures(value, significantFigures = 4) {
  if (value === 0) return "0.0000";
  const exponent = Math.floor(Math.log10(Math.abs(value)));
  const factor = Math.pow(10, significantFigures - 1 - exponent);
  return (Math.round(value * factor) / factor).toFixed(Math.max(0, significantFigures - 1 - exponent));
}

const Position = ({ position, id, name, inRange, tickLower, tickUpper, totalUSD, amount0, amount1, feeAPR, isHedgeable, hedgeStatus, network, provider, isShow }) => {
  const {
    liquidity,
    pool,
    token0Symbol,
    token1Symbol,
    token0addr,
    token1addr,
    token0Decimals,
    token1Decimals
  } = position;

  const networkConfig = network;

  const [hedgeSchema, setHedgeSchema] = useState([
    {
      "fr": 0.45,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.20,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0.15,
      "sl": 0.0005
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    },
    {
      "fr": 0,
      "sl": 0
    }
  ]);

  const [showHedge, setShowHedge] = useState(false);
  const [ButtonName, setButtonName] = useState('Hedge'); 
  const priceLower = tickToPrice(tickLower, token0Decimals, token1Decimals);
  const priceUpper = tickToPrice(tickUpper, token0Decimals, token1Decimals);
  const positionSize = amount0 + amount1;

  const handleSchemaChange = (index, key, value) => {
    const newSchema = [...hedgeSchema];
    newSchema[index][key] = value;
    setHedgeSchema(newSchema);
  }

  const handleChangeShow = ()  => {
    if (showHedge) setButtonName('Hedge'); else setButtonName('Decline');
    setShowHedge(!showHedge);
  }

  async function approveAutoExitContract(networkConfig, signer) {

      const contract = new ethers.Contract(
        networkConfig.uniswapNFTManagerAddress,
        [
          'function setApprovalForAll(address operator, bool approved) public'
        ],
        signer
      );
    
      await contract.setApprovalForAll(networkConfig.AutoExitAddress, true);
  }

  async function configureToken(
    networkConfig,
    signer,
    tokenId,
    token0Symbol,
    tickLower,
    tickUpper
  ) {
      const token0IsStable = isStablecoin(token0Symbol);
      const token0Swap = !token0IsStable;
      const token1Swap = token0IsStable;
    
      const config = {
        isActive: true,
        token0Swap: token0Swap,
        token1Swap: token1Swap,
        token0TriggerTick: tickLower,
        token1TriggerTick: tickUpper,
        token0SlippageX64: ethers.BigNumber.from("368934881474191032"),
        token1SlippageX64: ethers.BigNumber.from("368934881474191032"),
        onlyFees: false,
        maxRewardX64: ethers.BigNumber.from("184467440737095516"),
      };
    
      const contract = new ethers.Contract(
        networkConfig.uniswapNFTManagerAddress,
        [
          'function configToken(uint256 tokenId, (bool,bool,bool,int24,int24,uint64,uint64,bool,uint64) config) external',
        ],
        signer
      );
    
      const tx = await contract.configToken(tokenId, config);
    
      await tx.wait();
      console.log(`Token configuration transaction confirmed with hash: ${tx.hash}`);
  }

  const handleApproveHedge = async (positionId) => {
    try {
      console.log(Number(positionId));

      const signer = provider.getSigner();
      
      await approveAutoExitContract(networkConfig, signer);
      await configureToken(networkConfig, signer, positionId, token0Symbol, tickLower, tickUpper);

      const token = localStorage.getItem('authToken');
      const response = await axios.post('https://api.divedao.io/api/hedge', {
        chain: networkConfig.chain,
        position_id: Number(positionId),
        schema: hedgeSchema,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.status === 200) {
        alert('Hedge approved successfully!');
      } else {
        alert('Failed to approve hedge.');
      }
    } catch (error) {
      console.error('Error approving hedge:', error);
    }
  };

  return (
    isShow === 1 && (
    <PositionCard>
      <CardContent>
        <InfoRow>
          <Label variant="subtitle1">Token Pair:</Label>
          <a href={`https://app.uniswap.org/pools/${id}?chain=${network.chain}`}  target="_blank" variant="subtitle1">{name +" "+ (position.fee/10000) + " %" }</a>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenId={token0addr} symbol={token0Symbol} network={network} />
          <Value variant="subtitle1">{amount0}</Value>
        </InfoRow>
        <InfoRow>
          <TokenDetails tokenId={token1addr} symbol={token1Symbol} network={network} />
          <Value variant="subtitle1">{amount1}</Value>
        </InfoRow>
        
        <InfoRow>
          <Label variant="subtitle1">Range Status:</Label>
          <RangeIndicator variant="subtitle1" inRange={inRange}>
            {inRange ? '🟢 In Range' : '🔴 Out of Range'}
          </RangeIndicator>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Price Range:</Label>
          <Value variant="subtitle1">
            $ {formatToSignificantFigures(priceLower)} - $ {formatToSignificantFigures(priceUpper)}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Current Price:</Label>
          <Value variant="subtitle1">
            $ {formatToSignificantFigures(totalUSD)}
          </Value>
        </InfoRow>
        <InfoRow>
          <Label variant="subtitle1">Position Size:</Label>
          <Value variant="subtitle1">
            $ {formatToSignificantFigures(positionSize)}
          </Value>
        </InfoRow>

        {isHedgeable ? (
          <div>
            <Tooltip title="Decline the hedge and hide options" arrow>
              <Button 
                variant="contained" 
                color="primary"
                onClick={() => handleApproveHedge(id)}
                style={{ marginRight: 10 }}
              >
                {ButtonName}
              </Button>
            </Tooltip>
            <IconButton
              onClick={() => setShowHedge(!showHedge)}
              style={{ marginLeft: 10 }}
            >
              <SettingsIcon />
            </IconButton>
            {showHedge && (
              <div>
                <StyledTextField
                  multiline
                  rows={8}
                  variant="outlined"
                  value={JSON.stringify(hedgeSchema, null, 2)}
                  onChange={(e) => setHedgeSchema(JSON.parse(e.target.value))}
                />
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setShowHedge(false)}
                  style={{ marginTop: 10 }}
                >
                  Save
                </Button>
              </div>
            )}
            </div>
          ) : (
            <Typography variant="body2" color="textSecondary">
              Cannot hedge this position.
            </Typography>
          )}


      </CardContent>
    </PositionCard>
    )
  );
};

export default Position;
